


























































import { Component, Prop, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import { apiOrderConfirmPay } from '@/api/order'
import UploadVoucher from './upload-voucher.vue'
@Component({
    components: {
        LsDialog,
        LsPagination,
        UploadVoucher
    }
})
export default class OrderPane extends Vue {
    @Prop() value: any
    @Prop() pager!: any
    showPay = false
    orderId = 0
    selectIds: any = []

    remarks = '' //商家备注

    // 获取订单信息
    getOrderLists() {
        ; (this.$parent as any).getOrderLists()
    }
    confirmPay(id: number) {
        this.orderId = id
        this.showPay = true
    }
    // 去订单详情
    toOrder(id: any) {
        this.$router.push({
            path: '/order/renew/detail',
            query: { id }
        })
    }
}
